import React from 'react';
import './SignUpPage.css';

const SignUpPage: React.FC = () => {
    return (
        <div className="finbotix-signup">
            <header className="finbotix-signup-header">
                <div className="finbotix-logo">Finbotix</div>
                <a href="/" className="finbotix-back-link">← Back to Home</a>
            </header>

            <main className="signup-content">
                <h1 className="signup-title">Join Our Early Adopter Plan</h1>
                <p className="signup-subtitle">
                    Be among the first to experience Finbotix’s AI-driven financial insights.
                </p>

                <div className="plan-card">
                    <h2 className="plan-name">Early Adopter Plan</h2>
                    <p className="plan-price">$10 / month</p>
                    <ul className="plan-features">
                        <li>Real-time financial alerts</li>
                        <li>Personalized insights</li>
                        <li>Goal tracking and intelligent rebalancing</li>
                        <li>Exclusive early adopter perks</li>
                    </ul>
                    <button className="paypal-button">Pay with PayPal</button>
                    <p className="plan-note">You will be redirected to PayPal’s secure checkout.</p>
                </div>
            </main>

            <footer className="finbotix-signup-footer">
                <p>© {new Date().getFullYear()} Finbotix. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default SignUpPage;
