import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainPage from './MainPage';
import SignUpPage from './SignUpPage';

const App: React.FC = () => {
  return (
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/signup" element={<SignUpPage />} />
      </Routes>
  );
};

export default App;
